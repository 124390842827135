html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.prose {
  font-family: "Inter", sans-serif;
}

.prose strong {
  font-weight: 600;
}

.prose p {
  font-weight: normal;
}

.mdxeditor,
.mdxeditor-popup-container {
  z-index: 1;
}

.note table {
  width: 100%;
  border-collapse: collapse;
  background-color: transparent;
}

.note thead {
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.note tbody {
  font-size: 0.875rem;
  line-height: 1.43;
  vertical-align: inherit;
}

.note tr {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.note td,
.note th {
  padding: 8px;
  text-align: left;
  vertical-align: inherit;
  line-height: 1.43;
  border-bottom: 0;
}

.\[grid-template-areas\:_\'title_action\'_\'description_action\'\] {
  grid-template-areas: "title action" "description action"
}

.\[text-wrap\:pretty\] {
  text-wrap: pretty
}
#__next,
#root,
body,
html {
  height: 100%
}
.markdown {
  text-align: justify;
}
.markdown {
  max-width: none
}.markdown h1 {
  font-weight: 600;
  font-size: 2em; /* 32px */
}

.markdown h1:first-child {
  margin-top: 0;
}

.markdown h2 {
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 1.5em; /* 24px */
}

.markdown h2:first-child {
  margin-top: 0;
}

.markdown h3 {
  font-weight: 600;
  margin-bottom: .5rem;
  margin-top: 1rem;
  font-size: 1.17em; /* 18.72px */
}

.markdown h3:first-child {
  margin-top: 0;
}

.markdown h4 {
  font-weight: 400;
  margin-bottom: .5rem;
  margin-top: 1rem;
  font-size: 1em; /* 16px */
}

.markdown h4:first-child {
  margin-top: 0;
}

.markdown h5 {
  font-weight: 600;
  font-size: .83em; /* 13.28px */
}

.markdown h5:first-child {
  margin-top: 0;
}

.markdown blockquote {
  --tw-border-opacity: 1;
  border-color: rgba(155, 155, 155, var(--tw-border-opacity));
  border-left-width: 2px;
  line-height: 1.5rem;
  margin: 0;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-top: .5rem
}

.markdown blockquote>p {
  margin: 0
}

.markdown blockquote>p:after,
.markdown blockquote>p:before {
  display: none
}

.markdown ul {
  margin-bottom: 1rem;
  margin-top: 0;
  padding-left: 1.25rem
}

.markdown ul>li {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0
}

.markdown ul ul {
  margin-bottom: 0;
  padding-left: 1rem
}

.markdown ol {
  counter-reset: list-number;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0
}

.markdown ol,
.markdown ol>li {
  margin-bottom: 0;
  margin-top: 0
}

.markdown ol>li {
  counter-increment: list-number;
  padding-left: 0
}

.markdown ol>li:before {
  --tw-numeric-spacing: tabular-nums;
  content: counters(list-number, ".") ".";
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  padding-right: .5rem
}

.markdown ol ol>li {
  padding-left: 1.25rem
}

.markdown table {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-collapse: separate;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  margin-bottom: .25rem;
  margin-top: .25rem;
  width: 100%
}

.markdown th {
  background-color: hsla(0, 0%, 93%, .2);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: .25rem .75rem
}

.markdown th:first-child {
  border-top-left-radius: .375rem
}

.markdown th:last-child {
  border-right-width: 1px;
  border-top-right-radius: .375rem
}

.markdown td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding: .25rem .75rem
}

.markdown td:last-child {
  border-right-width: 1px
}

.markdown tbody tr:last-child td:first-child {
  border-bottom-left-radius: .375rem
}

.markdown tbody tr:last-child td:last-child {
  border-bottom-right-radius: .375rem
}

.markdown a {
  color: #2964aa;
  color: var(--link);
  font-weight: 400;
  text-decoration-line: none
}

.markdown a:hover {
  color: #749ac8;
  color: var(--link-hover)
}

.gizmo .markdown>:not(pre),
.gizmo .markdown>:not(pre)>:not(a) {
  color: #0d0d0d;
  color: var(--text-primary)
}

button.scroll-convo {
  display: none
}

@-webkit-keyframes blink {
  to {
    visibility: hidden
  }
}

@keyframes blink {
  to {
    visibility: hidden
  }
}

.result-streaming>:not(ol):not(ul):not(pre):last-child:after,
.result-streaming>pre:last-child code:after {
  content: "\25CF";
  font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
  line-height: normal;
  margin-left: .25rem;
  vertical-align: baseline
}

.result-thinking:empty:last-child:after {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-animation: pulseSize 1.25s ease-in-out infinite;
  animation: pulseSize 1.25s ease-in-out infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #0d0d0d;
  background-color: var(--text-primary);
  border-radius: 50%;
  box-sizing: border-box;
  content: " ";
  display: block;
  height: 12px;
  position: absolute;
  top: 7px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 12px;
  will-change: transform
}

@supports (selector(:has(*))) {

  .result-streaming>ol:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ol:last-child>li:last-child>ol:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ol:last-child>li:last-child>ol:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ol:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ol:last-child>li:last-child>ul:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ol:last-child>li:last-child>ul:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ol:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ol:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ul:last-child>li:last-child>ol:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child>ul:last-child>li:last-child>ul:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child[*|\:not-has\(]:after {
    content: "\25CF";
    font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    line-height: normal;
    margin-left: .25rem;
    vertical-align: baseline
  }

  .result-streaming>ul:last-child>li:last-child:not(:has(*>li)):after {
    content: "\25CF";
    font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    line-height: normal;
    margin-left: .25rem;
    vertical-align: baseline
  }

  .result-streaming>ol:last-child>li:last-child[*|\:not-has\(]:after {
    content: "\25CF";
    font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    line-height: normal;
    margin-left: .25rem;
    vertical-align: baseline
  }

  .result-streaming>ol:last-child>li:last-child:not(:has(*>li)):after {
    content: "\25CF";
    font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    line-height: normal;
    margin-left: .25rem;
    vertical-align: baseline
  }
}

@supports not (selector(:has(*))) {

  .result-streaming>ol:last-child>li:last-child:after,
  .result-streaming>ul:last-child>li:last-child:after {
    content: "\25CF";
    font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
    line-height: normal;
    margin-left: .25rem;
    vertical-align: baseline
  }
}

@-webkit-keyframes pulseSize {

  0%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.25, 1.25, 1);
    transform: scale3d(1.25, 1.25, 1)
  }
}

@keyframes pulseSize {

  0%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.25, 1.25, 1);
    transform: scale3d(1.25, 1.25, 1)
  }
}

@-webkit-keyframes toast-open {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes toast-open {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@-webkit-keyframes toast-close {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes toast-close {
  0% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

button[data-state=closed] .toggle-item-button-closed,
button[data-state=open] .toggle-item-button-open {
  display: none
}

.toast-root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 0;
  transition: all .24s cubic-bezier(0, 0, .2, 1)
}

.toast-root[data-state=entered],
.toast-root[data-state=entering] {
  -webkit-animation: toast-open .24s cubic-bezier(.175, .885, .32, 1.175) both;
  animation: toast-open .24s cubic-bezier(.175, .885, .32, 1.175) both
}

.toast-root[data-state=exiting] {
  -webkit-animation: toast-close .12s cubic-bezier(.4, 0, 1, 1) both;
  animation: toast-close .12s cubic-bezier(.4, 0, 1, 1) both
}

.toast-root .alert-root {
  box-shadow: 0 0 1px rgba(67, 90, 111, .3), 0 5px 8px -4px rgba(67, 90, 111, .3);
  flex-shrink: 0;
  pointer-events: all
}

.interact-bounce {
  transition-duration: .15s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.interact-bounce:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05
}

.interact-bounce:active,
.interact-bounce:hover {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.interact-bounce:active {
  --tw-scale-x: .9;
  --tw-scale-y: .9
}

.title {
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-family: SÃ¶hne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-variation-settings: normal
}

.font-circle {
  font-family: SÃ¶hne Circle, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif
}

.icon-xs {
  stroke-width: 1.5;
  flex-shrink: 0;
  height: .75rem;
  width: .75rem
}

.icon-sm {
  stroke-width: 2;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem
}

.icon-md {
  stroke-width: 1.5
}

.icon-md,
.icon-md-heavy {
  flex-shrink: 0;
  height: 18px;
  width: 18px
}

.icon-md-heavy {
  stroke-width: 2
}

.icon-lg {
  stroke-width: 1.5;
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem
}

.icon-lg-heavy {
  stroke-width: 2;
  flex-shrink: 0;
  height: 22px;
  width: 22px
}

.icon-xl {
  stroke-width: 1.5;
  flex-shrink: 0;
  height: 1.75rem;
  width: 1.75rem
}

.icon-xl-heavy {
  stroke-width: 2;
  flex-shrink: 0;
  height: 24px;
  width: 24px
}

.icon-2xl {
  stroke-width: 1.5;
  flex-shrink: 0;
  height: 2rem;
  width: 2rem
}

.icon-workspace-avatar-preview {
  stroke-width: 1.5;
  height: 96px;
  width: 96px
}

.icon-cover {
  stroke-width: 1.5;
  height: 234px;
  width: 234px
}

.icon-shimmer {
  -webkit-animation: icon-shimmer 5s cubic-bezier(.2, .44, .38, 1.02) infinite;
  animation: icon-shimmer 5s cubic-bezier(.2, .44, .38, 1.02) infinite;
  background-repeat: no-repeat;
  mask: linear-gradient(80deg, currentColor 30%, rgba(0, 0, 0, .333), currentColor 70%) right/300% 100%;
  -webkit-mask: linear-gradient(80deg, currentColor 30%, rgba(0, 0, 0, .333), currentColor 70%) right/300% 100%
}

@-webkit-keyframes icon-shimmer {
  0% {
    mask-position: left;
    -webkit-mask-position: right
  }

  20% {
    mask-position: left;
    -webkit-mask-position: left
  }

  to {
    mask-position: left;
    -webkit-mask-position: left
  }
}

@keyframes icon-shimmer {
  0% {
    mask-position: left;
    -webkit-mask-position: right
  }

  20% {
    mask-position: left;
    -webkit-mask-position: left
  }

  to {
    mask-position: left;
    -webkit-mask-position: left
  }
}

.gizmo-shadow-stroke {
  position: relative
}

.gizmo-shadow-stroke:after {
  --tw-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color);
  border-radius: 9999px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.dark .gizmo-shadow-stroke:after,
.gizmo-shadow-stroke:after {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.dark .gizmo-shadow-stroke:after {
  --tw-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .2);
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color)
}

.no-scrollbar::-webkit-scrollbar {
  display: none
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none
}

.dark .dark\:prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders)
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase
}

.marker\:text-token-text-tertiary ::marker {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.marker\:text-token-text-tertiary::marker {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.placeholder\:text-gray-300::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(205, 205, 205, var(--tw-text-opacity))
}

.placeholder\:text-gray-300::placeholder {
  --tw-text-opacity: 1;
  color: rgba(205, 205, 205, var(--tw-text-opacity))
}

.placeholder\:text-gray-500::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-text-opacity))
}

.placeholder\:text-gray-500::placeholder {
  --tw-text-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-text-opacity))
}

.placeholder\:text-gray-600::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(103, 103, 103, var(--tw-text-opacity))
}

.placeholder\:text-gray-600::placeholder {
  --tw-text-opacity: 1;
  color: rgba(103, 103, 103, var(--tw-text-opacity))
}

.placeholder\:text-token-text-tertiary::-webkit-input-placeholder {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.placeholder\:text-token-text-tertiary::placeholder {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute
}

.before\:right-0:before {
  content: var(--tw-content);
  right: 0
}

.before\:block:before {
  content: var(--tw-content);
  display: block
}

.before\:h-\[5px\]:before {
  content: var(--tw-content);
  height: 5px
}

.before\:w-\[5px\]:before {
  content: var(--tw-content);
  width: 5px
}

.before\:translate-y-\[-3px\]:before {
  --tw-translate-y: -3px;
  content: var(--tw-content);
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.before\:rounded-full:before {
  border-radius: 9999px;
  content: var(--tw-content)
}

.before\:bg-token-text-tertiary:before {
  background-color: #9b9b9b;
  background-color: var(--text-tertiary);
  content: var(--tw-content)
}

.after\:block:after {
  content: var(--tw-content);
  display: block
}

.after\:h-2:after {
  content: var(--tw-content);
  height: .5rem
}

.after\:h-\[5px\]:after {
  content: var(--tw-content);
  height: 5px
}

.after\:w-2:after {
  content: var(--tw-content);
  width: .5rem
}

.after\:w-\[5px\]:after {
  content: var(--tw-content);
  width: 5px
}

.after\:translate-y-\[-3px\]:after {
  --tw-translate-y: -3px;
  content: var(--tw-content);
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.after\:rounded-\[50\%\]:after {
  border-radius: 50%;
  content: var(--tw-content)
}

.after\:rounded-full:after {
  border-radius: 9999px;
  content: var(--tw-content)
}

.after\:bg-token-text-primary:after {
  background-color: #0d0d0d;
  background-color: var(--text-primary);
  content: var(--tw-content)
}

.after\:bg-token-text-tertiary:after {
  background-color: #9b9b9b;
  background-color: var(--text-tertiary);
  content: var(--tw-content)
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content)
}

.first\:ml-4:first-child {
  margin-left: 1rem
}

.first\:mt-0:first-child {
  margin-top: 0
}

.first\:border-0:first-child {
  border-width: 0
}

.last\:mb-0:last-child {
  margin-bottom: 0
}

.last\:mb-2:last-child {
  margin-bottom: .5rem
}

.last\:mr-4:last-child {
  margin-right: 1rem
}

.last\:min-h-\[calc\(100vh-8rem\)\]:last-child {
  min-height: calc(100vh - 8rem)
}

.last\:border-0:last-child {
  border-width: 0
}

.last\:border-b:last-child {
  border-bottom-width: 1px
}

.last\:border-b-0:last-child {
  border-bottom-width: 0
}

.last\:border-r-0:last-child {
  border-right-width: 0
}

.last\:pb-0:last-child {
  padding-bottom: 0
}

.last\:pb-2:last-child {
  padding-bottom: .5rem
}

.last\:pr-0:last-child {
  padding-right: 0
}

.last-of-type\:border-b-0:last-of-type {
  border-bottom-width: 0
}

.checked\:border-blue-600:checked {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

.checked\:border-token-text-secondary:checked {
  border-color: #676767;
  border-color: var(--text-secondary)
}

.checked\:\!bg-black:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important
}

.checked\:bg-blue-600:checked {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

.empty\:mt-0:empty {
  margin-top: 0
}

.empty\:hidden:empty {
  display: none
}

.focus-within\:z-10[focus-within] {
  z-index: 10
}

.focus-within\:z-10:focus-within {
  z-index: 10
}

.focus-within\:border-green-500[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus-within\:border-green-600[focus-within] {
  --tw-border-opacity: 1;
  border-color: rgba(16, 163, 127, var(--tw-border-opacity))
}

.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 163, 127, var(--tw-border-opacity))
}

.focus-within\:border-token-border-xheavy[focus-within] {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.focus-within\:border-token-border-xheavy:focus-within {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.focus-within\:bg-token-main-surface-tertiary[focus-within] {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.focus-within\:bg-token-main-surface-tertiary:focus-within {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.focus-within\:shadow-none[focus-within] {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.focus-within\:outline-none[focus-within] {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus-within\:ring-0[focus-within] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-1[focus-within] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-green-600[focus-within] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 163, 127, var(--tw-ring-opacity))
}

.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 163, 127, var(--tw-ring-opacity))
}

.focus-within\:ring-token-text-secondary[focus-within] {
  --tw-ring-color: var(--text-secondary)
}

.focus-within\:ring-token-text-secondary:focus-within {
  --tw-ring-color: var(--text-secondary)
}

.focus-within\:ring-transparent[focus-within] {
  --tw-ring-color: transparent
}

.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent
}

.hover\:line-clamp-none:hover {
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
  display: block;
  overflow: visible
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:overflow-visible:hover {
  overflow: visible
}

.hover\:border-\[var\(--avatar-color\)\]:hover {
  border-color: var(--avatar-color)
}

.hover\:border-black\/10:hover {
  border-color: rgba(0, 0, 0, .1)
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 236, 236, var(--tw-border-opacity))
}

.hover\:border-token-border-heavy:hover {
  border-color: rgba(0, 0, 0, .2);
  border-color: var(--border-heavy)
}

.hover\:border-token-border-medium:hover {
  border-color: rgba(0, 0, 0, .15);
  border-color: var(--border-medium)
}

.hover\:bg-\[\#0000FF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 255, var(--tw-bg-opacity))
}

.hover\:bg-\[\#0077FF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 119, 255, var(--tw-bg-opacity))
}

.hover\:bg-\[\#6E73FC\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 115, 252, var(--tw-bg-opacity))
}

.hover\:bg-\[var\(--snc-hover\)\]:hover {
  background-color: var(--snc-hover)
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.hover\:bg-black\/10:hover {
  background-color: rgba(0, 0, 0, .1)
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity))
}

.hover\:bg-brand-green\/30:hover {
  background-color: rgba(25, 195, 125, .3)
}

.hover\:bg-brand-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 104, 255, var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 236, 236, var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 227, 227, var(--tw-bg-opacity))
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity))
}

.hover\:bg-gray-500\/10:hover {
  background-color: hsla(0, 0%, 61%, .1)
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 78, 78, var(--tw-bg-opacity))
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(210, 244, 211, var(--tw-bg-opacity))
}

.hover\:bg-token-border-light:hover {
  background-color: rgba(0, 0, 0, .1);
  background-color: var(--border-light)
}

.hover\:bg-token-main-surface-primary:hover {
  background-color: #fff;
  background-color: var(--main-surface-primary)
}

.hover\:bg-token-main-surface-secondary:hover {
  background-color: #f9f9f9;
  background-color: var(--main-surface-secondary)
}

.hover\:bg-token-main-surface-tertiary:hover {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.hover\:bg-token-sidebar-surface-secondary:hover {
  background-color: #ececec;
  background-color: var(--sidebar-surface-secondary)
}

.hover\:bg-token-sidebar-surface-tertiary:hover {
  background-color: #cdcdcd;
  background-color: var(--sidebar-surface-tertiary)
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.hover\:bg-white\/40:hover {
  background-color: hsla(0, 0%, 100%, .4)
}

.hover\:text-black\/50:hover {
  color: rgba(0, 0, 0, .5)
}

.hover\:text-black\/70:hover {
  color: rgba(0, 0, 0, .7)
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity))
}

.hover\:text-token-link:hover {
  color: #2964aa;
  color: var(--link)
}

.hover\:text-token-link-hover:hover {
  color: #749ac8;
  color: var(--link-hover)
}

.hover\:text-token-text-primary:hover {
  color: #0d0d0d;
  color: var(--text-primary)
}

.hover\:text-token-text-secondary:hover {
  color: #676767;
  color: var(--text-secondary)
}

.hover\:text-token-text-tertiary:hover {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.hover\:text-white\/40:hover {
  color: hsla(0, 0%, 100%, .4)
}

.hover\:underline:hover {
  text-decoration-line: underline
}

.hover\:opacity-100:hover {
  opacity: 1
}

.hover\:opacity-50:hover {
  opacity: .5
}

.hover\:opacity-70:hover {
  opacity: .7
}

.hover\:opacity-75:hover {
  opacity: .75
}

.hover\:opacity-80:hover {
  opacity: .8
}

.hover\:brightness-90:hover {
  --tw-brightness: brightness(.9);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.hover\:delay-0:hover {
  transition-delay: 0s
}

.focus\:border-0:focus {
  border-width: 0
}

.focus\:border-none:focus {
  border-style: none
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(69, 89, 164, var(--tw-border-opacity))
}

.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(227, 227, 227, var(--tw-border-opacity))
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 163, 127, var(--tw-border-opacity))
}

.focus\:border-orange-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 128, 82, var(--tw-border-opacity))
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

.focus\:border-token-border-medium:focus {
  border-color: rgba(0, 0, 0, .15);
  border-color: var(--border-medium)
}

.focus\:border-token-text-primary:focus {
  border-color: #0d0d0d;
  border-color: var(--text-primary)
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent;
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-0:focus {
  outline-width: 0
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-0:focus,
.focus\:ring-1:focus {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(58, 136, 255, var(--tw-ring-opacity))
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(69, 89, 164, var(--tw-ring-opacity))
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(227, 227, 227, var(--tw-ring-opacity))
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(25, 195, 125, var(--tw-ring-opacity))
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 163, 127, var(--tw-ring-opacity))
}

.focus\:ring-token-text-primary:focus {
  --tw-ring-color: var(--text-primary)
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px
}

.focus-visible\:border-none.focus-visible {
  border-style: none
}

.focus-visible\:border-none:focus-visible {
  border-style: none
}

.focus-visible\:border-green-500.focus-visible {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus-visible\:border-green-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus-visible\:border-token-border-light.focus-visible {
  border-color: rgba(0, 0, 0, .1);
  border-color: var(--border-light)
}

.focus-visible\:border-token-border-light:focus-visible {
  border-color: rgba(0, 0, 0, .1);
  border-color: var(--border-light)
}

.focus-visible\:ring-0.focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-visible\:ring-0:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.active\:scale-\[0\.9\]:active {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.active\:opacity-50:active {
  opacity: .5
}

.active\:opacity-90:active {
  opacity: .9
}

.enabled\:bg-black:enabled {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.disabled\:cursor-auto:disabled {
  cursor: auto
}

.disabled\:bg-token-main-surface-tertiary:disabled {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.disabled\:text-gray-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(205, 205, 205, var(--tw-text-opacity))
}

.disabled\:text-gray-400:disabled {
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity))
}

.disabled\:text-token-border-medium:disabled {
  color: rgba(0, 0, 0, .15);
  color: var(--border-medium)
}

.disabled\:text-token-text-tertiary:disabled {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.disabled\:opacity-10:disabled {
  opacity: .1
}

.disabled\:opacity-20:disabled {
  opacity: .2
}

.disabled\:opacity-40:disabled {
  opacity: .4
}

.disabled\:hover\:bg-gray-900:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity))
}

.disabled\:hover\:bg-token-main-surface-tertiary:hover:disabled {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.disabled\:hover\:text-token-text-tertiary:hover:disabled {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.group:last-child .group-last\:border-b-0 {
  border-bottom-width: 0
}

.group:hover .group-hover\:visible,
.group\/dalle-image:hover .group-hover\/dalle-image\:visible {
  visibility: visible
}

.group:hover .group-hover\:block {
  display: block
}

.group:hover .group-hover\:flex {
  display: flex
}

.group:hover .group-hover\:hidden {
  display: none
}

.group:hover .group-hover\:w-20 {
  width: 5rem
}

.group:hover .group-hover\:border-t-\[\#0077FF\] {
  --tw-border-opacity: 1;
  border-top-color: rgba(0, 119, 255, var(--tw-border-opacity))
}

.group\/dalle-image:hover .group-hover\/dalle-image\:bg-black\/20 {
  background-color: rgba(0, 0, 0, .2)
}

.group\/dalle-image:hover .group-hover\/dalle-image\:bg-black\/70 {
  background-color: rgba(0, 0, 0, .7)
}

.group:hover .group-hover\:bg-token-main-surface-secondary {
  background-color: #f9f9f9;
  background-color: var(--main-surface-secondary)
}

.group:hover .group-hover\:from-token-main-surface-tertiary {
  --tw-gradient-from: var(--main-surface-tertiary) var(--tw-gradient-from-position);
  --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.group:hover .group-hover\:from-token-sidebar-surface-secondary {
  --tw-gradient-from: var(--sidebar-surface-secondary) var(--tw-gradient-from-position);
  --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.group:hover .group-hover\:from-60\% {
  --tw-gradient-from-position: 60%
}

.group:hover .group-hover\:pr-10 {
  padding-right: 2.5rem
}

.group:hover .group-hover\:text-token-link {
  color: #2964aa;
  color: var(--link)
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5
}

.group:hover .group-hover\:grayscale-0 {
  --tw-grayscale: grayscale(0);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.peer:focus~.peer-focus\:bg-token-text-primary {
  background-color: #0d0d0d;
  background-color: var(--text-primary)
}

.peer:focus~.peer-focus\:text-token-main-surface-primary {
  color: #fff;
  color: var(--main-surface-primary)
}

.peer:focus~.peer-focus\:hover\:opacity-80:hover {
  opacity: .8
}

.group[\:has\(\:focus\)] .group-has-\[\:focus\]\:border-token-border-xheavy {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.group:has(:focus) .group-has-\[\:focus\]\:border-token-border-xheavy {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.group[data-disabled] .group-data-\[disabled\]\:opacity-50 {
  opacity: .5
}

.radix-disabled\:pointer-events-none[data-disabled] {
  pointer-events: none
}

.radix-disabled\:cursor-auto[data-disabled] {
  cursor: auto
}

.radix-disabled\:bg-transparent[data-disabled] {
  background-color: transparent
}

.radix-disabled\:text-token-text-tertiary[data-disabled] {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.radix-disabled\:opacity-50[data-disabled] {
  opacity: .5
}

.radix-highlighted\:bg-gray-50[data-highlighted] {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity))
}

.radix-highlighted\:bg-token-main-surface-secondary[data-highlighted] {
  background-color: #f9f9f9;
  background-color: var(--main-surface-secondary)
}

.radix-highlighted\:outline-none[data-highlighted] {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.radix-state-active\:bg-token-main-surface-tertiary[data-state=active] {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.radix-state-active\:bg-white[data-state=active] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.radix-state-active\:text-token-text-primary[data-state=active] {
  color: #0d0d0d;
  color: var(--text-primary)
}

.radix-state-active\:text-token-text-secondary[data-state=active] {
  color: #676767;
  color: var(--text-secondary)
}

.radix-state-checked\:translate-x-\[13px\][data-state=checked] {
  --tw-translate-x: 13px
}

.radix-state-checked\:translate-x-\[13px\][data-state=checked],
.radix-state-checked\:translate-x-\[14px\][data-state=checked] {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.radix-state-checked\:translate-x-\[14px\][data-state=checked] {
  --tw-translate-x: 14px
}

.radix-state-checked\:border[data-state=checked] {
  border-width: 1px
}

.radix-state-checked\:border-green-500[data-state=checked] {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.radix-state-checked\:border-token-text-tertiary[data-state=checked] {
  border-color: #9b9b9b;
  border-color: var(--text-tertiary)
}

.radix-state-checked\:bg-green-500[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 195, 125, var(--tw-bg-opacity))
}

.radix-state-checked\:bg-green-600[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 163, 127, var(--tw-bg-opacity))
}

.radix-state-checked\:bg-token-main-surface-primary[data-state=checked] {
  background-color: #fff;
  background-color: var(--main-surface-primary)
}

.radix-state-checked\:bg-token-text-primary[data-state=checked] {
  background-color: #0d0d0d;
  background-color: var(--text-primary)
}

.radix-state-checked\:font-medium[data-state=checked] {
  font-weight: 500
}

.radix-state-checked\:text-token-main-surface-primary[data-state=checked] {
  color: #fff;
  color: var(--main-surface-primary)
}

.radix-state-checked\:text-token-text-primary[data-state=checked] {
  color: #0d0d0d;
  color: var(--text-primary)
}

.radix-state-checked\:shadow-\[0_0_2px_rgba\(0\2c 0\2c 0\2c \.03\)\][data-state=checked] {
  --tw-shadow: 0 0 2px rgba(0, 0, 0, .03);
  --tw-shadow-colored: 0 0 2px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

@-webkit-keyframes alertShow {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -48%) scale(.96);
    transform: translate(-50%, -48%) scale(.96)
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}

@keyframes alertShow {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -48%) scale(.96);
    transform: translate(-50%, -48%) scale(.96)
  }

  to {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}

.radix-state-open\:animate-alertShow[data-state=open] {
  -webkit-animation: alertShow .15s cubic-bezier(.16, 1, .3, 1);
  animation: alertShow .15s cubic-bezier(.16, 1, .3, 1)
}

@-webkit-keyframes contentShow {
  0% {
    opacity: 0;
    -webkit-transform: scale(.96);
    transform: scale(.96)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes contentShow {
  0% {
    opacity: 0;
    -webkit-transform: scale(.96);
    transform: scale(.96)
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

.radix-state-open\:animate-contentShow[data-state=open] {
  -webkit-animation: contentShow .15s cubic-bezier(.16, 1, .3, 1);
  animation: contentShow .15s cubic-bezier(.16, 1, .3, 1)
}

@-webkit-keyframes show {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes show {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.radix-state-open\:animate-show[data-state=open] {
  -webkit-animation: show .1s cubic-bezier(.16, 1, .3, 1);
  animation: show .1s cubic-bezier(.16, 1, .3, 1)
}

.radix-state-open\:bg-token-border-light[data-state=open] {
  background-color: rgba(0, 0, 0, .1);
  background-color: var(--border-light)
}

.radix-state-open\:bg-token-main-surface-secondary[data-state=open] {
  background-color: #f9f9f9;
  background-color: var(--main-surface-secondary)
}

.radix-state-open\:text-token-text-primary[data-state=open] {
  color: #0d0d0d;
  color: var(--text-primary)
}

.radix-state-open\:text-token-text-secondary[data-state=open] {
  color: #676767;
  color: var(--text-secondary)
}

.radix-state-open\:text-token-text-tertiary[data-state=open] {
  color: #9b9b9b;
  color: var(--text-tertiary)
}

.group[data-state=open] .group-radix-state-open\:bg-token-main-surface-tertiary {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

@-webkit-keyframes slideUpAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2px);
    transform: translateY(2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2px);
    transform: translateY(2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

.radix-side-bottom\:animate-slideUpAndFade[data-side=bottom] {
  -webkit-animation: slideUpAndFade .4s cubic-bezier(.16, 1, .3, 1);
  animation: slideUpAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@-webkit-keyframes slideRightAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.radix-side-left\:animate-slideRightAndFade[data-side=left] {
  -webkit-animation: slideRightAndFade .4s cubic-bezier(.16, 1, .3, 1);
  animation: slideRightAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2px);
    transform: translateX(2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
}

.radix-side-right\:animate-slideLeftAndFade[data-side=right] {
  -webkit-animation: slideLeftAndFade .4s cubic-bezier(.16, 1, .3, 1);
  animation: slideLeftAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

.radix-side-top\:animate-slideDownAndFade[data-side=top] {
  -webkit-animation: slideDownAndFade .4s cubic-bezier(.16, 1, .3, 1);
  animation: slideDownAndFade .4s cubic-bezier(.16, 1, .3, 1)
}

.group-ui-open\:bg-token-sidebar-surface-secondary.group[data-headlessui-state~=open] {
  background-color: #ececec;
  background-color: var(--sidebar-surface-secondary)
}

.group:where([data-headlessui-state~=open]) .group-ui-open\:bg-token-sidebar-surface-secondary {
  background-color: #ececec;
  background-color: var(--sidebar-surface-secondary)
}

.ZnJ1aXRqdWljZQ .juice\:hidden {
  display: none
}

.ZnJ1aXRqdWljZQ .juice\:justify-end {
  justify-content: flex-end
}

.ZnJ1aXRqdWljZQ .juice\:gap-4 {
  gap: 1rem
}

@media (hover:hover) and (pointer:fine) {
  .group:hover .can-hover\:group-hover\:text-token-text-primary {
    color: #0d0d0d;
    color: var(--text-primary)
  }

  .group:hover .can-hover\:group-hover\:opacity-100 {
    opacity: 1
  }
}

.dark\:divide-gray-900:is(.dark *)>:not([hidden])~:not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(23, 23, 23, var(--tw-divide-opacity))
}

.dark .dark\:border {
  border-width: 1px
}

.dark .dark\:border-b {
  border-bottom-width: 1px
}

.dark .dark\:border-r {
  border-right-width: 1px
}

.dark .dark\:border-black\/20 {
  border-color: rgba(0, 0, 0, .2)
}

.dark .dark\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(227, 227, 227, var(--tw-border-opacity))
}

.dark .dark\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(205, 205, 205, var(--tw-border-opacity))
}

.dark .dark\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(155, 155, 155, var(--tw-border-opacity))
}

.dark .dark\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 103, 103, var(--tw-border-opacity))
}

.dark .dark\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(78, 78, 78, var(--tw-border-opacity))
}

.dark .dark\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity))
}

.dark .dark\:border-token-border-heavy {
  border-color: rgba(0, 0, 0, .2);
  border-color: var(--border-heavy)
}

.dark .dark\:border-token-border-medium {
  border-color: rgba(0, 0, 0, .15);
  border-color: var(--border-medium)
}

.dark .dark\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark .dark\:border-white\/10 {
  border-color: hsla(0, 0%, 100%, .1)
}

.dark .dark\:border-white\/20 {
  border-color: hsla(0, 0%, 100%, .2)
}

.dark .dark\:border-white\/30 {
  border-color: hsla(0, 0%, 100%, .3)
}

.dark .dark\:border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark .dark\:bg-\[\#272832\] {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 40, 50, var(--tw-bg-opacity))
}

.dark .dark\:bg-\[rgba\(255\2c 255\2c 255\2c 0\.15\)\] {
  background-color: hsla(0, 0%, 100%, .15)
}

.dark .dark\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.dark .dark\:bg-black\/25 {
  background-color: rgba(0, 0, 0, .25)
}

.dark .dark\:bg-black\/80 {
  background-color: rgba(0, 0, 0, .8)
}

.dark .dark\:bg-brand-green\/50 {
  background-color: rgba(25, 195, 125, .5)
}

.dark .dark\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 236, 236, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(155, 155, 155, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 103, 103, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 78, 78, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity))
}

.dark .dark\:bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 108, 43, var(--tw-bg-opacity))
}

.dark .dark\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

.dark .dark\:bg-token-main-surface-tertiary {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.dark .dark\:bg-transparent {
  background-color: transparent
}

.dark .dark\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark .dark\:bg-white\/5 {
  background-color: hsla(0, 0%, 100%, .05)
}

.dark .dark\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 114, 1, var(--tw-bg-opacity))
}

.dark .dark\:from-gray-800 {
  --tw-gradient-from: #262626 var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(38, 38, 38, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.dark .dark\:via-white\/5 {
  --tw-gradient-to: hsla(0, 0%, 100%, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), hsla(0, 0%, 100%, .05) var(--tw-gradient-via-position), var(--tw-gradient-to)
}

.dark .dark\:stroke-brand-purple\/50 {
  stroke: rgba(171, 104, 255, .5)
}

.dark .dark\:text-\[\#D292FF\] {
  --tw-text-opacity: 1;
  color: rgba(210, 146, 255, var(--tw-text-opacity))
}

.dark .dark\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

.dark .dark\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(58, 136, 255, var(--tw-text-opacity))
}

.dark .dark\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark .dark\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(78, 78, 78, var(--tw-text-opacity))
}

.dark .dark\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(23, 23, 23, var(--tw-text-opacity))
}

.dark .dark\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(210, 244, 211, var(--tw-text-opacity))
}

.dark .dark\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(104, 222, 122, var(--tw-text-opacity))
}

.dark .dark\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity))
}

.dark .dark\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity))
}

.dark .dark\:text-token-text-secondary {
  color: #676767;
  color: var(--text-secondary)
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark .dark\:text-white\/60 {
  color: hsla(0, 0%, 100%, .6)
}

.dark .dark\:text-white\/70 {
  color: hsla(0, 0%, 100%, .7)
}

.dark\:placeholder-white\/50:is(.dark *)::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, .5)
}

.dark\:placeholder-white\/50:is(.dark *)::placeholder {
  color: hsla(0, 0%, 100%, .5)
}

.dark .dark\:opacity-60 {
  opacity: .6
}

.dark .dark\:shadow-\[inset_0_0_0_1px_rgba\(255\2c 255\2c 255\2c 0\.1\)\] {
  --tw-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .1);
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color)
}

.dark .dark\:shadow-\[inset_0_0_0_1px_rgba\(255\2c 255\2c 255\2c 0\.1\)\],
.dark .dark\:shadow-none {
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.dark .dark\:shadow-none {
  --tw-shadow: 0 0 transparent;
  --tw-shadow-colored: 0 0 transparent
}

.dark .dark\:ring-white\/20 {
  --tw-ring-color: hsla(0, 0%, 100%, .2)
}

.dark\:placeholder\:text-token-text-secondary:is(.dark *)::-webkit-input-placeholder {
  color: #676767;
  color: var(--text-secondary)
}

.dark\:placeholder\:text-token-text-secondary:is(.dark *)::placeholder {
  color: #676767;
  color: var(--text-secondary)
}

.placeholder\:dark\:text-white:is(.dark *)::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.placeholder\:dark\:text-white:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark\:last\:border-0:last-child:is(.dark *) {
  border-width: 0
}

.dark\:focus-within\:border-token-border-xheavy[focus-within]:is(.dark *) {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.dark\:focus-within\:border-token-border-xheavy:focus-within:is(.dark *) {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.dark\:focus-within\:ring-0[focus-within]:is(.dark *) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.dark\:focus-within\:ring-0:focus-within:is(.dark *) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.dark\:hover\:border-gray-800:hover:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(38, 38, 38, var(--tw-border-opacity))
}

.dark\:hover\:border-white:hover:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark\:hover\:bg-brand-green\/40:hover:is(.dark *) {
  background-color: rgba(25, 195, 125, .4)
}

.dark\:hover\:bg-gray-200:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 227, 227, var(--tw-bg-opacity))
}

.dark\:hover\:bg-gray-500\/10:hover:is(.dark *) {
  background-color: hsla(0, 0%, 61%, .1)
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 78, 78, var(--tw-bg-opacity))
}

.dark\:hover\:bg-gray-800:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity))
}

.dark\:hover\:bg-token-main-surface-secondary:hover:is(.dark *) {
  background-color: #f9f9f9;
  background-color: var(--main-surface-secondary)
}

.dark\:hover\:bg-white:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark\:hover\:bg-white\/10:hover:is(.dark *) {
  background-color: hsla(0, 0%, 100%, .1)
}

.dark\:hover\:bg-white\/20:hover:is(.dark *) {
  background-color: hsla(0, 0%, 100%, .2)
}

.hover\:dark\:bg-white\/10:is(.dark *):hover {
  background-color: hsla(0, 0%, 100%, .1)
}

.dark\:hover\:text-blue-600:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark\:hover\:text-token-text-primary:hover:is(.dark *) {
  color: #0d0d0d;
  color: var(--text-primary)
}

.dark\:hover\:text-white\/50:hover:is(.dark *) {
  color: hsla(0, 0%, 100%, .5)
}

.dark\:focus\:border-white:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark\:focus\:ring-gray-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(103, 103, 103, var(--tw-ring-opacity))
}

.dark\:focus\:ring-white:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.dark\:focus-visible\:border-green-500.focus-visible:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.dark\:focus-visible\:border-green-500:focus-visible:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.dark\:disabled\:text-gray-400:disabled:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity))
}

.dark\:disabled\:hover\:bg-white:hover:disabled:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark\:radix-disabled\:bg-transparent[data-disabled]:is(.dark *) {
  background-color: transparent
}

.dark\:radix-highlighted\:bg-gray-700[data-highlighted]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 78, 78, var(--tw-bg-opacity))
}

.dark\:radix-state-active\:bg-token-main-surface-tertiary[data-state=active]:is(.dark *) {
  background-color: #ececec;
  background-color: var(--main-surface-tertiary)
}

.dark\:radix-state-open\:text-gray-400[data-state=open]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(180, 180, 180, var(--tw-text-opacity))
}

@media (min-width:640px) {
  .sm\:absolute {
    position: absolute
  }

  .sm\:left-16 {
    left: 4rem
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }

  .sm\:mb-2 {
    margin-bottom: .5rem
  }

  .sm\:mb-2\.5 {
    margin-bottom: .625rem
  }

  .sm\:mb-3 {
    margin-bottom: .75rem
  }

  .sm\:mb-4 {
    margin-bottom: 1rem
  }

  .sm\:mt-0 {
    margin-top: 0
  }

  .sm\:mt-16 {
    margin-top: 4rem
  }

  .sm\:mt-4 {
    margin-top: 1rem
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:h-10 {
    height: 2.5rem
  }

  .sm\:w-10 {
    width: 2.5rem
  }

  .sm\:w-\[calc\(\(100\%-12px\)\/2\)\] {
    width: calc(50% - 6px)
  }

  .sm\:max-w-2xl {
    max-width: 42rem
  }

  .sm\:max-w-\[400px\] {
    max-width: 400px
  }

  .sm\:max-w-xs {
    max-width: 20rem
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr))
  }

  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:gap-3 {
    gap: .75rem
  }

  .sm\:gap-y-0 {
    row-gap: 0
  }

  .sm\:rounded-lg {
    border-radius: .5rem
  }

  .sm\:rounded-md {
    border-radius: .375rem
  }

  .sm\:rounded-t-\[30px\] {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px
  }

  .sm\:p-10 {
    padding: 2.5rem
  }

  .sm\:p-2 {
    padding: .5rem
  }

  .sm\:p-3 {
    padding: .75rem
  }

  .sm\:p-4 {
    padding: 1rem
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:pb-0 {
    padding-bottom: 0
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem
  }

  .sm\:pb-2 {
    padding-bottom: .5rem
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem
  }

  .sm\:pr-2 {
    padding-right: .5rem
  }

  .sm\:pt-12 {
    padding-top: 3rem
  }

  .sm\:pt-4 {
    padding-top: 1rem
  }

  .sm\:pt-8 {
    padding-top: 2rem
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem
  }

  .sm\:duration-300 {
    transition-duration: .3s
  }

  .sm\:duration-500 {
    transition-duration: .5s
  }

  @supports (min-height:100dvh) {
    .sm\:supports-\[min-height\:100dvh\]\:min-h-\[100dvh\] {
      min-height: 100dvh
    }
  }
}

@media (min-width:768px) {
  .md\:invisible {
    visibility: hidden
  }

  .md\:static {
    position: static
  }

  .md\:bottom-2 {
    bottom: .5rem
  }

  .md\:bottom-3 {
    bottom: .75rem
  }

  .md\:left-4 {
    left: 1rem
  }

  .md\:left-\[23px\] {
    left: 23px
  }

  .md\:right-3 {
    right: .75rem
  }

  .md\:right-\[21px\] {
    right: 21px
  }

  .md\:top-\[150px\] {
    top: 150px
  }

  .md\:top-\[21px\] {
    top: 21px
  }

  .md\:top-\[22px\] {
    top: 22px
  }

  .md\:top-\[250px\] {
    top: 250px
  }

  .md\:col-span-1 {
    grid-column: span 1/span 1
  }

  .md\:col-span-2 {
    grid-column: span 2/span 2
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:m-auto {
    margin: auto
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
  }

  .md\:my-4 {
    margin-bottom: 1rem;
    margin-top: 1rem
  }

  .md\:-mb-16 {
    margin-bottom: -4rem
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mb-3 {
    margin-bottom: .75rem
  }

  .md\:mb-4 {
    margin-bottom: 1rem
  }

  .md\:ml-0 {
    margin-left: 0
  }

  .md\:ml-8 {
    margin-left: 2rem
  }

  .md\:ml-\[-8px\] {
    margin-left: -8px
  }

  .md\:mt-2 {
    margin-top: .5rem
  }

  .md\:mt-3 {
    margin-top: .75rem
  }

  .md\:mt-5 {
    margin-top: 1.25rem
  }

  .md\:line-clamp-3 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden
  }

  .md\:block {
    display: block
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:grid {
    display: grid
  }

  .md\:hidden {
    display: none
  }

  .md\:h-14 {
    height: 3.5rem
  }

  .md\:h-16 {
    height: 4rem
  }

  .md\:h-24 {
    height: 6rem
  }

  .md\:h-32 {
    height: 8rem
  }

  .md\:h-6 {
    height: 1.5rem
  }

  .md\:h-\[30px\] {
    height: 30px
  }

  .md\:h-\[72px\] {
    height: 72px
  }

  .md\:max-h-\[80vh\] {
    max-height: 80vh
  }

  .md\:max-h-\[calc\(100vh-300px\)\] {
    max-height: calc(100vh - 300px)
  }

  .md\:min-h-0 {
    min-height: 0
  }

  .md\:min-h-\[380px\] {
    min-height: 380px
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-16 {
    width: 4rem
  }

  .md\:w-24 {
    width: 6rem
  }

  .md\:w-3\/5 {
    width: 60%
  }

  .md\:w-6 {
    width: 1.5rem
  }

  .md\:w-\[100px\] {
    width: 100px
  }

  .md\:w-\[250px\] {
    width: 250px
  }

  .md\:w-\[26rem\] {
    width: 26rem
  }

  .md\:w-\[30px\] {
    width: 30px
  }

  .md\:w-\[500px\] {
    width: 500px
  }

  .md\:w-\[672px\] {
    width: 672px
  }

  .md\:w-\[calc\(100\%-\.5rem\)\] {
    width: calc(100% - .5rem)
  }

  .md\:w-\[calc\(100\%-16rem\)\] {
    width: calc(100% - 16rem)
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-full {
    width: 100%
  }

  .md\:min-w-\[180px\] {
    min-width: 180px
  }

  .md\:min-w-\[672px\] {
    min-width: 672px
  }

  .md\:max-w-2xl {
    max-width: 42rem
  }

  .md\:max-w-3xl {
    max-width: 48rem
  }

  .md\:max-w-4xl {
    max-width: 56rem
  }

  .md\:max-w-\[672px\] {
    max-width: 672px
  }

  .md\:max-w-\[680px\] {
    max-width: 680px
  }

  .md\:max-w-lg {
    max-width: 32rem
  }

  .md\:max-w-none {
    max-width: none
  }

  .md\:max-w-xs {
    max-width: 20rem
  }

  .md\:grow-0 {
    flex-grow: 0
  }

  .md\:basis-0 {
    flex-basis: 0px
  }

  .md\:basis-\[25vw\] {
    flex-basis: 25vw
  }

  .md\:basis-\[75vw\] {
    flex-basis: 75vw
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-rows-\[minmax\(20px\2c _1fr\)_auto_20px\] {
    grid-template-rows: minmax(20px, 1fr) auto 20px
  }

  .md\:grid-rows-\[minmax\(20px\2c _1fr\)_auto_minmax\(20px\2c _1fr\)\] {
    grid-template-rows: minmax(20px, 1fr) auto minmax(20px, 1fr)
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:items-start {
    align-items: flex-start
  }

  .md\:justify-between {
    justify-content: space-between
  }

  .md\:gap-2 {
    gap: .5rem
  }

  .md\:gap-3 {
    gap: .75rem
  }

  .md\:gap-6 {
    gap: 1.5rem
  }

  .md\:gap-x-2 {
    -webkit-column-gap: .5rem;
    column-gap: .5rem
  }

  .md\:gap-y-1 {
    row-gap: .25rem
  }

  .md\:gap-y-1\.5 {
    row-gap: .375rem
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:overflow-visible {
    overflow: visible
  }

  .md\:whitespace-normal {
    white-space: normal
  }

  .md\:rounded-2xl {
    border-radius: 1rem
  }

  .md\:rounded-lg {
    border-radius: .5rem
  }

  .md\:rounded-none {
    border-radius: 0
  }

  .md\:border {
    border-width: 1px
  }

  .md\:border-b-2 {
    border-bottom-width: 2px
  }

  .md\:border-l {
    border-left-width: 1px
  }

  .md\:border-r {
    border-right-width: 1px
  }

  .md\:border-t-0 {
    border-top-width: 0
  }

  .md\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 236, 236, var(--tw-border-opacity))
  }

  .md\:border-transparent {
    border-color: transparent
  }

  .md\:\!bg-transparent {
    background-color: transparent !important
  }

  .md\:bg-transparent {
    background-color: transparent
  }

  .md\:bg-vert-light-gradient {
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 13.94%, #fff 54.73%)
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-0\.5 {
    padding: .125rem
  }

  .md\:p-2 {
    padding: .5rem
  }

  .md\:p-6 {
    padding: 1.5rem
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:px-\[60px\] {
    padding-left: 60px;
    padding-right: 60px
  }

  .md\:py-2 {
    padding-bottom: .5rem;
    padding-top: .5rem
  }

  .md\:py-3 {
    padding-bottom: .75rem;
    padding-top: .75rem
  }

  .md\:py-3\.5 {
    padding-bottom: .875rem;
    padding-top: .875rem
  }

  .md\:py-4 {
    padding-bottom: 1rem;
    padding-top: 1rem
  }

  .md\:py-6 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
  }

  .md\:py-\[22px\] {
    padding-bottom: 22px;
    padding-top: 22px
  }

  .md\:\!pt-0 {
    padding-top: 0 !important
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem
  }

  .md\:pl-0 {
    padding-left: 0
  }

  .md\:pl-16 {
    padding-left: 4rem
  }

  .md\:pl-2 {
    padding-left: .5rem
  }

  .md\:pl-4 {
    padding-left: 1rem
  }

  .md\:pl-6 {
    padding-left: 1.5rem
  }

  .md\:pl-\[55px\] {
    padding-left: 55px
  }

  .md\:pr-12 {
    padding-right: 3rem
  }

  .md\:pr-3 {
    padding-right: .75rem
  }

  .md\:pr-4 {
    padding-right: 1rem
  }

  .md\:pr-8 {
    padding-right: 2rem
  }

  .md\:pr-\[72px\] {
    padding-right: 72px
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pt-4 {
    padding-top: 1rem
  }

  .md\:pt-5 {
    padding-top: 1.25rem
  }

  .md\:text-justify {
    text-align: justify
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1
  }

  .md\:text-\[32px\] {
    font-size: 32px
  }

  .md\:text-\[40px\] {
    font-size: 40px
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
  }

  .md\:leading-8 {
    line-height: 2rem
  }

  .md\:leading-tight {
    line-height: 1.25
  }

  .md\:text-token-text-primary {
    color: #0d0d0d;
    color: var(--text-primary)
  }

  .md\:text-token-text-tertiary {
    color: #9b9b9b;
    color: var(--text-tertiary)
  }

  .md\:opacity-0 {
    opacity: 0
  }

  .md\:first\:ml-0:first-child {
    margin-left: 0
  }

  .md\:last\:mb-6:last-child {
    margin-bottom: 1.5rem
  }

  .md\:last\:mr-0:last-child {
    margin-right: 0
  }

  .md\:last\:pb-6:last-child {
    padding-bottom: 1.5rem
  }

  .md\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 249, 249, var(--tw-bg-opacity))
  }

  .group.final-completion .md\:group-\[\.final-completion\]\:visible,
  .group:hover .md\:group-hover\:visible {
    visibility: visible
  }

  .md\:radix-state-active\:bg-token-main-surface-secondary[data-state=active] {
    background-color: #f9f9f9;
    background-color: var(--main-surface-secondary)
  }

  .md\:radix-state-active\:bg-token-main-surface-tertiary[data-state=active] {
    background-color: #ececec;
    background-color: var(--main-surface-tertiary)
  }

  .md\:radix-state-active\:text-token-text-primary[data-state=active] {
    color: #0d0d0d;
    color: var(--text-primary)
  }

  .ZnJ1aXRqdWljZQ .juice\:md\:hidden {
    display: none
  }

  .ZnJ1aXRqdWljZQ .juice\:md\:gap-6 {
    gap: 1.5rem
  }

  .dark .md\:dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(78, 78, 78, var(--tw-border-opacity))
  }

  .dark .md\:dark\:border-transparent {
    border-color: transparent
  }

  .dark .dark\:md\:bg-transparent {
    background-color: transparent
  }

  .dark .dark\:md\:bg-vert-dark-gradient {
    background-image: linear-gradient(180deg, rgba(53, 55, 64, 0), #353740 58.85%)
  }

  .dark\:md\:hover\:bg-gray-700:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgba(78, 78, 78, var(--tw-bg-opacity))
  }
}

@media (min-width:1024px) {
  .lg\:relative {
    position: relative
  }

  .lg\:-left-3 {
    left: -.75rem
  }

  .lg\:-right-3 {
    right: -.75rem
  }

  .lg\:left-auto {
    left: auto
  }

  .lg\:top-1\/2 {
    top: 50%
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:flex {
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:h-36 {
    height: 9rem
  }

  .lg\:w-3\/5 {
    width: 60%
  }

  .lg\:w-\[300px\] {
    width: 300px
  }

  .lg\:w-\[896px\] {
    width: 896px
  }

  .lg\:w-\[calc\(\(100\%-24px\)\/3\)\] {
    width: calc(33.33333% - 8px)
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:min-w-\[896px\] {
    min-width: 896px
  }

  .lg\:\!max-w-lg {
    max-width: 32rem !important
  }

  .lg\:max-w-2xl {
    max-width: 42rem
  }

  .lg\:max-w-\[38rem\] {
    max-width: 38rem
  }

  .lg\:max-w-\[40rem\] {
    max-width: 40rem
  }

  .lg\:max-w-\[796px\] {
    max-width: 796px
  }

  .lg\:max-w-\[896px\] {
    max-width: 896px
  }

  .lg\:max-w-md {
    max-width: 28rem
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-sm {
    max-width: 24rem
  }

  .lg\:-translate-x-full {
    --tw-translate-x: -100%
  }

  .lg\:-translate-x-full,
  .lg\:-translate-y-1\/2 {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%
  }

  .lg\:translate-x-full {
    --tw-translate-x: 100%
  }

  .lg\:translate-x-full,
  .lg\:translate-y-0 {
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:translate-y-0 {
    --tw-translate-y: 0px
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-\[60\%_40\%\] {
    grid-template-columns: 60% 40%
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:justify-start {
    justify-content: flex-start
  }

  .lg\:justify-between {
    justify-content: space-between
  }

  .lg\:gap-x-3 {
    -webkit-column-gap: .75rem;
    column-gap: .75rem
  }

  .lg\:gap-y-2 {
    row-gap: .5rem
  }

  .lg\:gap-y-2\.5 {
    row-gap: .625rem
  }

  .lg\:self-center {
    align-self: center
  }

  .lg\:rounded-lg {
    border-radius: .5rem
  }

  .lg\:rounded-none {
    border-radius: 0
  }

  .lg\:border {
    border-width: 1px
  }

  .lg\:border-0 {
    border-width: 0
  }

  .lg\:p-0 {
    padding: 0
  }

  .lg\:p-6 {
    padding: 1.5rem
  }

  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:py-10 {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem
  }

  .lg\:py-3 {
    padding-bottom: .75rem;
    padding-top: .75rem
  }

  .lg\:py-6 {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem
  }

  .lg\:pl-8 {
    padding-left: 2rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pr-10 {
    padding-right: 2.5rem
  }

  .lg\:pt-32 {
    padding-top: 8rem
  }

  .lg\:text-left {
    text-align: left
  }

  .lg\:text-\[22px\] {
    font-size: 22px
  }
}

@media (min-width:1280px) {
  .xl\:w-\[1024px\] {
    width: 1024px
  }

  .xl\:w-\[350px\] {
    width: 350px
  }

  .xl\:min-w-\[1024px\] {
    min-width: 1024px
  }

  .xl\:max-w-3xl {
    max-width: 48rem
  }

  .xl\:max-w-4xl {
    max-width: 56rem
  }

  .xl\:max-w-6xl {
    max-width: 72rem
  }

  .xl\:max-w-\[48rem\] {
    max-width: 48rem
  }

  .xl\:max-w-xl {
    max-width: 36rem
  }

  .xl\:max-w-xs {
    max-width: 20rem
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }
}

@media (min-width:1536px) {
  .\32xl\:w-\[400px\] {
    width: 400px
  }
}

@media (min-width:901px) {
  .snc-md\:right-\[--snc-3\] {
    right: var(--snc-3)
  }

  .snc-md\:ml-\[--snc-4\] {
    margin-left: var(--snc-4)
  }
}

@media (min-width:1131px) {
  .snc-lg\:ml-0 {
    margin-left: 0
  }

  .snc-lg\:flex {
    display: flex
  }
}

@media (min-width:1806px) {
  .snc-2xl\:mb-\[-0\.4375rem\] {
    margin-bottom: -.4375rem
  }
}

.\[\&\:has\(textarea\:focus\)\]\:min-h-\[124px\][\:has\(textarea\:focus\)] {
  min-height: 124px
}

.\[\&\:has\(textarea\:focus\)\]\:min-h-\[124px\]:has(textarea:focus) {
  min-height: 124px
}

.\[\&\:has\(textarea\:focus\)\]\:border-token-border-xheavy[\:has\(textarea\:focus\)] {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.\[\&\:has\(textarea\:focus\)\]\:border-token-border-xheavy:has(textarea:focus) {
  border-color: rgba(0, 0, 0, .25);
  border-color: var(--border-xheavy)
}

.\[\&\:has\(textarea\:focus\)\]\:shadow-\[0_2px_6px_rgba\(0\2c 0\2c 0\2c \.05\)\][\:has\(textarea\:focus\)] {
  --tw-shadow: 0 2px 6px rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 2px 6px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.\[\&\:has\(textarea\:focus\)\]\:shadow-\[0_2px_6px_rgba\(0\2c 0\2c 0\2c \.05\)\]:has(textarea:focus) {
  --tw-shadow: 0 2px 6px rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 2px 6px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.\[\&\:has\(textarea\:focus\)\]\:shadow-\[0_4px_12px_rgba\(0\2c 0\2c 0\2c \.08\)\2c 0_0_0_1px_var\(--border-heavy\)\][\:has\(textarea\:focus\)] {
  --tw-shadow: 0 4px 12px rgba(0, 0, 0, .08), 0 0 0 1px var(--border-heavy);
  --tw-shadow-colored: 0 4px 12px var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.\[\&\:has\(textarea\:focus\)\]\:shadow-\[0_4px_12px_rgba\(0\2c 0\2c 0\2c \.08\)\2c 0_0_0_1px_var\(--border-heavy\)\]:has(textarea:focus) {
  --tw-shadow: 0 4px 12px rgba(0, 0, 0, .08), 0 0 0 1px var(--border-heavy);
  --tw-shadow-colored: 0 4px 12px var(--tw-shadow-color), 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.\[\&\>div\:nth-child\(2\)\]\:overflow-y-hidden>div:nth-child(2) {
  overflow-y: hidden
}

.text-message+.\[\.text-message\+\&\]\:mt-5 {
  margin-top: 1.25rem
}

tr:last-child .\[tr\:last-child_\&\]\:border-b-0 {
  border-bottom-width: 0
}

tr[data-disabled=true] .\[tr\[data-disabled\=true\]_\&\]\:opacity-50 {
  opacity: .5
}

@font-face {
  font-family: KaTeX_AMS;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_AMS-Regular.a79f1c31.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Caligraphic-Bold.ec17d132.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Caligraphic-Regular.55fac258.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Fraktur-Bold.d42a5579.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Fraktur-Regular.d3c882a6.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Main-Bold.c3fb5ac2.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Main-BoldItalic.6f2bb1df.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Main-Italic.8916142b.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Main-Regular.0462f03b.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Math-BoldItalic.572d331f.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Math-Italic.f28c23ac.woff2) format("woff2")
}

@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 700;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_SansSerif-Bold.8c5b5494.woff2) format("woff2")
}

@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: italic;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_SansSerif-Italic.3b1e59b3.woff2) format("woff2")
}

@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_SansSerif-Regular.ba21ed5f.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Script;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Script-Regular.03e9641d.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Size1;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Size1-Regular.eae34984.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Size2;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Size2-Regular.5916a24f.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Size3;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Size3-Regular.b4230e7e.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Size4;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Size4-Regular.10d95fd3.woff2) format("woff2")
}

@font-face {
  font-family: KaTeX_Typewriter;
  font-style: normal;
  font-weight: 400;
  src: url(https://cdn.oaistatic.com/_next/static/media/KaTeX_Typewriter-Regular.a8709e36.woff2) format("woff2")
}

.katex {
  font: normal 1.21em KaTeX_Main, Times New Roman, serif;
  line-height: 1.2;
  text-indent: 0;
  text-rendering: auto
}

.katex * {
  -ms-high-contrast-adjust: none !important;
  border-color: currentcolor
}

.katex .katex-version:after {
  content: "0.16.0"
}

.katex .katex-mathml {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}

.katex .katex-html>.newline {
  display: block
}

.katex .base {
  position: relative;
  white-space: nowrap;
  width: -webkit-min-content;
  width: min-content
}

.katex .base,
.katex .strut {
  display: inline-block
}

.katex .textbf {
  font-weight: 700
}

.katex .textit {
  font-style: italic
}

.katex .textrm {
  font-family: KaTeX_Main
}

.katex .textsf {
  font-family: "KaTeX_SansSerif"
}

.katex .texttt {
  font-family: KaTeX_Typewriter
}

.katex .mathnormal {
  font-family: KaTeX_Math;
  font-style: italic
}

.katex .mathit {
  font-family: KaTeX_Main;
  font-style: italic
}

.katex .mathrm {
  font-style: normal
}

.katex .mathbf {
  font-family: KaTeX_Main;
  font-weight: 700
}

.katex .boldsymbol {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 700
}

.katex .amsrm,
.katex .mathbb,
.katex .textbb {
  font-family: KaTeX_AMS
}

.katex .mathcal {
  font-family: KaTeX_Caligraphic
}

.katex .mathfrak,
.katex .textfrak {
  font-family: KaTeX_Fraktur
}

.katex .mathtt {
  font-family: KaTeX_Typewriter
}

.katex .mathscr,
.katex .textscr {
  font-family: KaTeX_Script
}

.katex .mathsf,
.katex .textsf {
  font-family: "KaTeX_SansSerif"
}

.katex .mathboldsf,
.katex .textboldsf {
  font-family: "KaTeX_SansSerif";
  font-weight: 700
}

.katex .mathitsf,
.katex .textitsf {
  font-family: "KaTeX_SansSerif";
  font-style: italic
}

.katex .mainrm {
  font-family: KaTeX_Main;
  font-style: normal
}

.katex .vlist-t {
  border-collapse: collapse;
  display: inline-table;
  table-layout: fixed
}

.katex .vlist-r {
  display: table-row
}

.katex .vlist {
  display: table-cell;
  position: relative;
  vertical-align: bottom
}

.katex .vlist>span {
  display: block;
  height: 0;
  position: relative
}

.katex .vlist>span>span {
  display: inline-block
}

.katex .vlist>span>.pstrut {
  overflow: hidden;
  width: 0
}

.katex .vlist-t2 {
  margin-right: -2px
}

.katex .vlist-s {
  display: table-cell;
  font-size: 1px;
  min-width: 2px;
  vertical-align: bottom;
  width: 2px
}

.katex .vbox {
  align-items: baseline;
  display: inline-flex;
  flex-direction: column
}

.katex .hbox {
  width: 100%
}

.katex .hbox,
.katex .thinbox {
  display: inline-flex;
  flex-direction: row
}

.katex .thinbox {
  max-width: 0;
  width: 0
}

.katex .msupsub {
  text-align: left
}

.katex .mfrac>span>span {
  text-align: center
}

.katex .mfrac .frac-line {
  border-bottom-style: solid;
  display: inline-block;
  width: 100%
}

.katex .hdashline,
.katex .hline,
.katex .mfrac .frac-line,
.katex .overline .overline-line,
.katex .rule,
.katex .underline .underline-line {
  min-height: 1px
}

.katex .mspace {
  display: inline-block
}

.katex .clap,
.katex .llap,
.katex .rlap {
  position: relative;
  width: 0
}

.katex .clap>.inner,
.katex .llap>.inner,
.katex .rlap>.inner {
  position: absolute
}

.katex .clap>.fix,
.katex .llap>.fix,
.katex .rlap>.fix {
  display: inline-block
}

.katex .llap>.inner {
  right: 0
}

.katex .clap>.inner,
.katex .rlap>.inner {
  left: 0
}

.katex .clap>.inner>span {
  margin-left: -50%;
  margin-right: 50%
}

.katex .rule {
  border: 0 solid;
  display: inline-block;
  position: relative
}

.katex .hline,
.katex .overline .overline-line,
.katex .underline .underline-line {
  border-bottom-style: solid;
  display: inline-block;
  width: 100%
}

.katex .hdashline {
  border-bottom-style: dashed;
  display: inline-block;
  width: 100%
}

.katex .sqrt>.root {
  margin-left: .27777778em;
  margin-right: -.55555556em
}

.katex .fontsize-ensurer.reset-size1.size1,
.katex .sizing.reset-size1.size1 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size1.size2,
.katex .sizing.reset-size1.size2 {
  font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size1.size3,
.katex .sizing.reset-size1.size3 {
  font-size: 1.4em
}

.katex .fontsize-ensurer.reset-size1.size4,
.katex .sizing.reset-size1.size4 {
  font-size: 1.6em
}

.katex .fontsize-ensurer.reset-size1.size5,
.katex .sizing.reset-size1.size5 {
  font-size: 1.8em
}

.katex .fontsize-ensurer.reset-size1.size6,
.katex .sizing.reset-size1.size6 {
  font-size: 2em
}

.katex .fontsize-ensurer.reset-size1.size7,
.katex .sizing.reset-size1.size7 {
  font-size: 2.4em
}

.katex .fontsize-ensurer.reset-size1.size8,
.katex .sizing.reset-size1.size8 {
  font-size: 2.88em
}

.katex .fontsize-ensurer.reset-size1.size9,
.katex .sizing.reset-size1.size9 {
  font-size: 3.456em
}

.katex .fontsize-ensurer.reset-size1.size10,
.katex .sizing.reset-size1.size10 {
  font-size: 4.148em
}

.katex .fontsize-ensurer.reset-size1.size11,
.katex .sizing.reset-size1.size11 {
  font-size: 4.976em
}

.katex .fontsize-ensurer.reset-size2.size1,
.katex .sizing.reset-size2.size1 {
  font-size: .83333333em
}

.katex .fontsize-ensurer.reset-size2.size2,
.katex .sizing.reset-size2.size2 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size2.size3,
.katex .sizing.reset-size2.size3 {
  font-size: 1.16666667em
}

.katex .fontsize-ensurer.reset-size2.size4,
.katex .sizing.reset-size2.size4 {
  font-size: 1.33333333em
}

.katex .fontsize-ensurer.reset-size2.size5,
.katex .sizing.reset-size2.size5 {
  font-size: 1.5em
}

.katex .fontsize-ensurer.reset-size2.size6,
.katex .sizing.reset-size2.size6 {
  font-size: 1.66666667em
}

.katex .fontsize-ensurer.reset-size2.size7,
.katex .sizing.reset-size2.size7 {
  font-size: 2em
}

.katex .fontsize-ensurer.reset-size2.size8,
.katex .sizing.reset-size2.size8 {
  font-size: 2.4em
}

.katex .fontsize-ensurer.reset-size2.size9,
.katex .sizing.reset-size2.size9 {
  font-size: 2.88em
}

.katex .fontsize-ensurer.reset-size2.size10,
.katex .sizing.reset-size2.size10 {
  font-size: 3.45666667em
}

.katex .fontsize-ensurer.reset-size2.size11,
.katex .sizing.reset-size2.size11 {
  font-size: 4.14666667em
}

.katex .fontsize-ensurer.reset-size3.size1,
.katex .sizing.reset-size3.size1 {
  font-size: .71428571em
}

.katex .fontsize-ensurer.reset-size3.size2,
.katex .sizing.reset-size3.size2 {
  font-size: .85714286em
}

.katex .fontsize-ensurer.reset-size3.size3,
.katex .sizing.reset-size3.size3 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size3.size4,
.katex .sizing.reset-size3.size4 {
  font-size: 1.14285714em
}

.katex .fontsize-ensurer.reset-size3.size5,
.katex .sizing.reset-size3.size5 {
  font-size: 1.28571429em
}

.katex .fontsize-ensurer.reset-size3.size6,
.katex .sizing.reset-size3.size6 {
  font-size: 1.42857143em
}

.katex .fontsize-ensurer.reset-size3.size7,
.katex .sizing.reset-size3.size7 {
  font-size: 1.71428571em
}

.katex .fontsize-ensurer.reset-size3.size8,
.katex .sizing.reset-size3.size8 {
  font-size: 2.05714286em
}

.katex .fontsize-ensurer.reset-size3.size9,
.katex .sizing.reset-size3.size9 {
  font-size: 2.46857143em
}

.katex .fontsize-ensurer.reset-size3.size10,
.katex .sizing.reset-size3.size10 {
  font-size: 2.96285714em
}

.katex .fontsize-ensurer.reset-size3.size11,
.katex .sizing.reset-size3.size11 {
  font-size: 3.55428571em
}

.katex .fontsize-ensurer.reset-size4.size1,
.katex .sizing.reset-size4.size1 {
  font-size: .625em
}

.katex .fontsize-ensurer.reset-size4.size2,
.katex .sizing.reset-size4.size2 {
  font-size: .75em
}

.katex .fontsize-ensurer.reset-size4.size3,
.katex .sizing.reset-size4.size3 {
  font-size: .875em
}

.katex .fontsize-ensurer.reset-size4.size4,
.katex .sizing.reset-size4.size4 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size4.size5,
.katex .sizing.reset-size4.size5 {
  font-size: 1.125em
}

.katex .fontsize-ensurer.reset-size4.size6,
.katex .sizing.reset-size4.size6 {
  font-size: 1.25em
}

.katex .fontsize-ensurer.reset-size4.size7,
.katex .sizing.reset-size4.size7 {
  font-size: 1.5em
}

.katex .fontsize-ensurer.reset-size4.size8,
.katex .sizing.reset-size4.size8 {
  font-size: 1.8em
}

.katex .fontsize-ensurer.reset-size4.size9,
.katex .sizing.reset-size4.size9 {
  font-size: 2.16em
}

.katex .fontsize-ensurer.reset-size4.size10,
.katex .sizing.reset-size4.size10 {
  font-size: 2.5925em
}

.katex .fontsize-ensurer.reset-size4.size11,
.katex .sizing.reset-size4.size11 {
  font-size: 3.11em
}

.katex .fontsize-ensurer.reset-size5.size1,
.katex .sizing.reset-size5.size1 {
  font-size: .55555556em
}

.katex .fontsize-ensurer.reset-size5.size2,
.katex .sizing.reset-size5.size2 {
  font-size: .66666667em
}

.katex .fontsize-ensurer.reset-size5.size3,
.katex .sizing.reset-size5.size3 {
  font-size: .77777778em
}

.katex .fontsize-ensurer.reset-size5.size4,
.katex .sizing.reset-size5.size4 {
  font-size: .88888889em
}

.katex .fontsize-ensurer.reset-size5.size5,
.katex .sizing.reset-size5.size5 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size5.size6,
.katex .sizing.reset-size5.size6 {
  font-size: 1.11111111em
}

.katex .fontsize-ensurer.reset-size5.size7,
.katex .sizing.reset-size5.size7 {
  font-size: 1.33333333em
}

.katex .fontsize-ensurer.reset-size5.size8,
.katex .sizing.reset-size5.size8 {
  font-size: 1.6em
}

.katex .fontsize-ensurer.reset-size5.size9,
.katex .sizing.reset-size5.size9 {
  font-size: 1.92em
}

.katex .fontsize-ensurer.reset-size5.size10,
.katex .sizing.reset-size5.size10 {
  font-size: 2.30444444em
}

.katex .fontsize-ensurer.reset-size5.size11,
.katex .sizing.reset-size5.size11 {
  font-size: 2.76444444em
}

.katex .fontsize-ensurer.reset-size6.size1,
.katex .sizing.reset-size6.size1 {
  font-size: .5em
}

.katex .fontsize-ensurer.reset-size6.size2,
.katex .sizing.reset-size6.size2 {
  font-size: .6em
}

.katex .fontsize-ensurer.reset-size6.size3,
.katex .sizing.reset-size6.size3 {
  font-size: .7em
}

.katex .fontsize-ensurer.reset-size6.size4,
.katex .sizing.reset-size6.size4 {
  font-size: .8em
}

.katex .fontsize-ensurer.reset-size6.size5,
.katex .sizing.reset-size6.size5 {
  font-size: .9em
}

.katex .fontsize-ensurer.reset-size6.size6,
.katex .sizing.reset-size6.size6 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size6.size7,
.katex .sizing.reset-size6.size7 {
  font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size6.size8,
.katex .sizing.reset-size6.size8 {
  font-size: 1.44em
}

.katex .fontsize-ensurer.reset-size6.size9,
.katex .sizing.reset-size6.size9 {
  font-size: 1.728em
}

.katex .fontsize-ensurer.reset-size6.size10,
.katex .sizing.reset-size6.size10 {
  font-size: 2.074em
}

.katex .fontsize-ensurer.reset-size6.size11,
.katex .sizing.reset-size6.size11 {
  font-size: 2.488em
}

.katex .fontsize-ensurer.reset-size7.size1,
.katex .sizing.reset-size7.size1 {
  font-size: .41666667em
}

.katex .fontsize-ensurer.reset-size7.size2,
.katex .sizing.reset-size7.size2 {
  font-size: .5em
}

.katex .fontsize-ensurer.reset-size7.size3,
.katex .sizing.reset-size7.size3 {
  font-size: .58333333em
}

.katex .fontsize-ensurer.reset-size7.size4,
.katex .sizing.reset-size7.size4 {
  font-size: .66666667em
}

.katex .fontsize-ensurer.reset-size7.size5,
.katex .sizing.reset-size7.size5 {
  font-size: .75em
}

.katex .fontsize-ensurer.reset-size7.size6,
.katex .sizing.reset-size7.size6 {
  font-size: .83333333em
}

.katex .fontsize-ensurer.reset-size7.size7,
.katex .sizing.reset-size7.size7 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size7.size8,
.katex .sizing.reset-size7.size8 {
  font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size7.size9,
.katex .sizing.reset-size7.size9 {
  font-size: 1.44em
}

.katex .fontsize-ensurer.reset-size7.size10,
.katex .sizing.reset-size7.size10 {
  font-size: 1.72833333em
}

.katex .fontsize-ensurer.reset-size7.size11,
.katex .sizing.reset-size7.size11 {
  font-size: 2.07333333em
}

.katex .fontsize-ensurer.reset-size8.size1,
.katex .sizing.reset-size8.size1 {
  font-size: .34722222em
}

.katex .fontsize-ensurer.reset-size8.size2,
.katex .sizing.reset-size8.size2 {
  font-size: .41666667em
}

.katex .fontsize-ensurer.reset-size8.size3,
.katex .sizing.reset-size8.size3 {
  font-size: .48611111em
}

.katex .fontsize-ensurer.reset-size8.size4,
.katex .sizing.reset-size8.size4 {
  font-size: .55555556em
}

.katex .fontsize-ensurer.reset-size8.size5,
.katex .sizing.reset-size8.size5 {
  font-size: .625em
}

.katex .fontsize-ensurer.reset-size8.size6,
.katex .sizing.reset-size8.size6 {
  font-size: .69444444em
}

.katex .fontsize-ensurer.reset-size8.size7,
.katex .sizing.reset-size8.size7 {
  font-size: .83333333em
}

.katex .fontsize-ensurer.reset-size8.size8,
.katex .sizing.reset-size8.size8 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size8.size9,
.katex .sizing.reset-size8.size9 {
  font-size: 1.2em
}

.katex .fontsize-ensurer.reset-size8.size10,
.katex .sizing.reset-size8.size10 {
  font-size: 1.44027778em
}

.katex .fontsize-ensurer.reset-size8.size11,
.katex .sizing.reset-size8.size11 {
  font-size: 1.72777778em
}

.katex .fontsize-ensurer.reset-size9.size1,
.katex .sizing.reset-size9.size1 {
  font-size: .28935185em
}

.katex .fontsize-ensurer.reset-size9.size2,
.katex .sizing.reset-size9.size2 {
  font-size: .34722222em
}

.katex .fontsize-ensurer.reset-size9.size3,
.katex .sizing.reset-size9.size3 {
  font-size: .40509259em
}

.katex .fontsize-ensurer.reset-size9.size4,
.katex .sizing.reset-size9.size4 {
  font-size: .46296296em
}

.katex .fontsize-ensurer.reset-size9.size5,
.katex .sizing.reset-size9.size5 {
  font-size: .52083333em
}

.katex .fontsize-ensurer.reset-size9.size6,
.katex .sizing.reset-size9.size6 {
  font-size: .5787037em
}

.katex .fontsize-ensurer.reset-size9.size7,
.katex .sizing.reset-size9.size7 {
  font-size: .69444444em
}

.katex .fontsize-ensurer.reset-size9.size8,
.katex .sizing.reset-size9.size8 {
  font-size: .83333333em
}

.katex .fontsize-ensurer.reset-size9.size9,
.katex .sizing.reset-size9.size9 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size9.size10,
.katex .sizing.reset-size9.size10 {
  font-size: 1.20023148em
}

.katex .fontsize-ensurer.reset-size9.size11,
.katex .sizing.reset-size9.size11 {
  font-size: 1.43981481em
}

.katex .fontsize-ensurer.reset-size10.size1,
.katex .sizing.reset-size10.size1 {
  font-size: .24108004em
}

.katex .fontsize-ensurer.reset-size10.size2,
.katex .sizing.reset-size10.size2 {
  font-size: .28929605em
}

.katex .fontsize-ensurer.reset-size10.size3,
.katex .sizing.reset-size10.size3 {
  font-size: .33751205em
}

.katex .fontsize-ensurer.reset-size10.size4,
.katex .sizing.reset-size10.size4 {
  font-size: .38572806em
}

.katex .fontsize-ensurer.reset-size10.size5,
.katex .sizing.reset-size10.size5 {
  font-size: .43394407em
}

.katex .fontsize-ensurer.reset-size10.size6,
.katex .sizing.reset-size10.size6 {
  font-size: .48216008em
}

.katex .fontsize-ensurer.reset-size10.size7,
.katex .sizing.reset-size10.size7 {
  font-size: .57859209em
}

.katex .fontsize-ensurer.reset-size10.size8,
.katex .sizing.reset-size10.size8 {
  font-size: .69431051em
}

.katex .fontsize-ensurer.reset-size10.size9,
.katex .sizing.reset-size10.size9 {
  font-size: .83317261em
}

.katex .fontsize-ensurer.reset-size10.size10,
.katex .sizing.reset-size10.size10 {
  font-size: 1em
}

.katex .fontsize-ensurer.reset-size10.size11,
.katex .sizing.reset-size10.size11 {
  font-size: 1.19961427em
}

.katex .fontsize-ensurer.reset-size11.size1,
.katex .sizing.reset-size11.size1 {
  font-size: .20096463em
}

.katex .fontsize-ensurer.reset-size11.size2,
.katex .sizing.reset-size11.size2 {
  font-size: .24115756em
}

.katex .fontsize-ensurer.reset-size11.size3,
.katex .sizing.reset-size11.size3 {
  font-size: .28135048em
}

.katex .fontsize-ensurer.reset-size11.size4,
.katex .sizing.reset-size11.size4 {
  font-size: .32154341em
}

.katex .fontsize-ensurer.reset-size11.size5,
.katex .sizing.reset-size11.size5 {
  font-size: .36173633em
}

.katex .fontsize-ensurer.reset-size11.size6,
.katex .sizing.reset-size11.size6 {
  font-size: .40192926em
}

.katex .fontsize-ensurer.reset-size11.size7,
.katex .sizing.reset-size11.size7 {
  font-size: .48231511em
}

.katex .fontsize-ensurer.reset-size11.size8,
.katex .sizing.reset-size11.size8 {
  font-size: .57877814em
}

.katex .fontsize-ensurer.reset-size11.size9,
.katex .sizing.reset-size11.size9 {
  font-size: .69453376em
}

.katex .fontsize-ensurer.reset-size11.size10,
.katex .sizing.reset-size11.size10 {
  font-size: .83360129em
}

.katex .fontsize-ensurer.reset-size11.size11,
.katex .sizing.reset-size11.size11 {
  font-size: 1em
}

.katex .delimsizing.size1 {
  font-family: KaTeX_Size1
}

.katex .delimsizing.size2 {
  font-family: KaTeX_Size2
}

.katex .delimsizing.size3 {
  font-family: KaTeX_Size3
}

.katex .delimsizing.size4 {
  font-family: KaTeX_Size4
}

.katex .delimsizing.mult .delim-size1>span {
  font-family: KaTeX_Size1
}

.katex .delimsizing.mult .delim-size4>span {
  font-family: KaTeX_Size4
}

.katex .nulldelimiter {
  display: inline-block;
  width: .12em
}

.katex .delimcenter,
.katex .op-symbol {
  position: relative
}

.katex .op-symbol.small-op {
  font-family: KaTeX_Size1
}

.katex .op-symbol.large-op {
  font-family: KaTeX_Size2
}

.katex .accent>.vlist-t,
.katex .op-limits>.vlist-t {
  text-align: center
}

.katex .accent .accent-body {
  position: relative
}

.katex .accent .accent-body:not(.accent-full) {
  width: 0
}

.katex .overlay {
  display: block
}

.katex .mtable .vertical-separator {
  display: inline-block;
  min-width: 1px
}

.katex .mtable .arraycolsep {
  display: inline-block
}

.katex .mtable .col-align-c>.vlist-t {
  text-align: center
}

.katex .mtable .col-align-l>.vlist-t {
  text-align: left
}

.katex .mtable .col-align-r>.vlist-t {
  text-align: right
}

.katex .svg-align {
  text-align: left
}

.katex svg {
  fill: currentcolor;
  stroke: currentcolor;
  fill-rule: nonzero;
  fill-opacity: 1;
  stroke-width: 1;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1;
  display: block;
  height: inherit;
  position: absolute;
  width: 100%
}

.katex svg path {
  stroke: none
}

.katex img {
  border-style: none;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0
}

.katex .stretchy {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%
}

.katex .stretchy:after,
.katex .stretchy:before {
  content: ""
}

.katex .hide-tail {
  overflow: hidden;
  position: relative;
  width: 100%
}

.katex .halfarrow-left {
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 50.2%
}

.katex .halfarrow-right {
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 50.2%
}

.katex .brace-left {
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 25.1%
}

.katex .brace-center {
  left: 25%;
  overflow: hidden;
  position: absolute;
  width: 50%
}

.katex .brace-right {
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 25.1%
}

.katex .x-arrow-pad {
  padding: 0 .5em
}

.katex .cd-arrow-pad {
  padding: 0 .55556em 0 .27778em
}

.katex .mover,
.katex .munder,
.katex .x-arrow {
  text-align: center
}

.katex .boxpad {
  padding: 0 .3em
}

.katex .fbox,
.katex .fcolorbox {
  border: .04em solid;
  box-sizing: border-box
}

.katex .cancel-pad {
  padding: 0 .2em
}

.katex .cancel-lap {
  margin-left: -.2em;
  margin-right: -.2em
}

.katex .sout {
  border-bottom-style: solid;
  border-bottom-width: .08em
}

.katex .angl {
  border-right: .049em solid;
  border-top: .049em solid;
  box-sizing: border-box;
  margin-right: .03889em
}

.katex .anglpad {
  padding: 0 .03889em
}

.katex .eqn-num:before {
  content: "(" counter(katexEqnNo) ")";
  counter-increment: katexEqnNo
}

.katex .mml-eqn-num:before {
  content: "(" counter(mmlEqnNo) ")";
  counter-increment: mmlEqnNo
}

.katex .mtr-glue {
  width: 50%
}

.katex .cd-vert-arrow {
  display: inline-block;
  position: relative
}

.katex .cd-label-left {
  display: inline-block;
  position: absolute;
  right: calc(50% + .3em);
  text-align: left
}

.katex .cd-label-right {
  display: inline-block;
  left: calc(50% + .3em);
  position: absolute;
  text-align: right
}

.katex-display {
  display: block;
  margin: 1em 0;
  text-align: center
}

.katex-display>.katex {
  display: block;
  text-align: center;
  white-space: nowrap
}

.katex-display>.katex>.katex-html {
  display: block;
  position: relative
}

.katex-display>.katex>.katex-html>.tag {
  position: absolute;
  right: 0
}

.katex-display.leqno>.katex>.katex-html>.tag {
  left: 0;
  right: auto
}

.katex-display.fleqn>.katex {
  padding-left: 2em;
  text-align: left
}

body {
  counter-reset: katexEqnNo mmlEqnNo
}

.snc {
  height: 100%;
  margin: 0;
  overflow: hidden
}

.snc .text-bubble {
  font-size: var(--snc-text-default)
}

.snc .text-bubble .prose p:first-child {
  margin-bottom: 0
}

.snc .snc-accent-border {
  border: 1px solid #e0e0e0
}

.snc .wrapper {
  background-color: var(--main-surface-primary);
  display: flex;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  padding-left: var(--snc-2);
  padding-right: var(--snc-2);
  width: 100%
}

@media (min-width:901px) {
  .snc .wrapper {
    background-color: var(--main-surface-primary);
    justify-content: flex-start;
    padding: 0
  }
}

.snc .content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: var(--snc-message-max);
  min-width: var(--snc-message-min);
  width: var(--snc-message-w)
}

.snc .platform-nav {
  align-items: center;
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  gap: .5rem;
  justify-content: flex-end;
  margin-bottom: var(--snc-2);
  padding-bottom: .5rem;
  width: var(--snc-4)
}

@media (min-width:1131px) {
  .snc .platform-nav {
    display: flex
  }
}

.snc .main-column {
  flex-grow: 1;
  max-width: var(--snc-message-max);
  position: relative;
  width: var(--snc-message-w)
}

.snc .messages-body {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  height: calc(100vh - var(--snc-header-height));
  overflow-y: auto;
  position: relative
}

.snc .followup-input {
  padding-bottom: var(--snc-2);
  padding-left: var(--snc-1);
  padding-right: var(--snc-1)
}

.snc .followup-input,
.snc .followup-input:before {
  background-color: var(--main-surface-primary);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0
}

.snc .followup-input:before {
  content: var(--tw-content);
  height: calc(100% - var(--snc-1));
  width: 100%
}

.snc .followup-input:is(.dark *) {
  background-color: var(--main-surface-tertiary)
}

.snc .followup-input:is(.dark *):before {
  background-color: var(--main-surface-tertiary);
  content: var(--tw-content)
}

.snc .links-body {
  display: none;
  flex-shrink: 0;
  position: relative
}

@media (min-width:901px) {
  .snc .links-body {
    display: block
  }
}

@media (min-width:1131px) {
  .snc .links-body {
    padding-left: 0
  }
}

.snc .links-body {
  gap: var(--snc-1);
  margin-right: var(--snc-3);
  max-height: 100%;
  max-width: var(--snc-link-max);
  min-height: 100%;
  min-width: var(--snc-link-min);
  overflow: auto;
  padding-bottom: var(--snc-2);
  padding-left: var(--snc-2);
  padding-top: calc(var(--snc-header-height) + var(--snc-2));
  width: var(--snc-link-w)
}

.snc .links-body .source-title {
  background-color: var(--main-surface-secondary);
  color: var(--text-primary);
  display: flex;
  display: none;
  gap: var(--snc-1);
  height: calc(var(--snc-2)*2.333);
  margin-bottom: var(--snc-1);
  padding-top: var(--snc-2);
  position: fixed;
  top: var(--snc-header-height);
  width: 640px;
  z-index: 1
}

.snc .links-body .more-btn {
  color: var(--text-primary);
  display: flex;
  gap: var(--snc-1);
  padding: var(--snc-1) 0;
  width: 640px
}

.snc .links-body .more-btn:hover {
  color: var(--link)
}

.snc .links-body .link-result {
  align-items: flex-start;
  display: flex;
  gap: var(--snc-1);
  position: relative;
  width: 100%
}

.snc .links-body .link-result .link-text {
  border-bottom: 1px solid var(--border-light);
  padding-bottom: var(--snc-1)
}

.snc {
  --snc-1: 24px;
  --snc-2: 16px;
  --snc-3: 44px;
  --snc-4: 0.001px;
  --snc-header-height: 130px;
  --snc-header-right: 36px;
  --snc-message-w: 100%;
  --snc-link-w: 0%;
  --snc-message-max: 100%;
  --snc-message-min: 0;
  --snc-link-max: 500px;
  --snc-link-min: 0;
  --snc-text-base: 15px;
  --snc-gutter-display: none;
  --snc-text-default: 1rem;
  --snc-text-notation: 0.8rem;
  --snc-text-subtitle: 0.92rem;
  --snc-text-lg: 1.35rem;
  --snc-image-height: 150px;
  --snc-hover: rgba(0, 0, 0, .03);
  --main-surface-secondary: #f7f7f7;
  --main-surface-tertiary: #f1f1f1;
  --link: #2e36db;
  font-size: var(--snc-text-base)
}

.snc .text-xs {
  font-size: var(--snc-text-notation);
  line-height: 1.5rem
}

.snc .text-sm {
  font-size: var(--snc-text-subtitle);
  line-height: 1.5rem
}

.snc .text-base {
  font-size: var(--snc-text-default);
  line-height: 1.5rem
}

.snc .text-lg {
  font-size: var(--snc-text-lg);
  line-height: 1.75rem
}

.snc .snc-accent-border {
  border: 0
}

.snc.dark {
  --snc-hover: hsla(0, 0%, 100%, .03);
  --snc-focus-border: hsla(0, 0%, 100%, .75);
  --main-surface-primary: var(--gray-950);
  --main-surface-secondary: var(--gray-900);
  --main-surface-tertiary: var(--gray-800);
  --link: #aadafa;
  --border-light: hsla(0, 0%, 100%, .05);
  --border-xheavy: hsla(0, 0%, 100%, .2)
}

.snc.dark .snc-accent-border {
  border: .5px solid var(--border-xheavy)
}

.snc.dark .prose {
  --link: #aadafa
}

.snc .prose {
  --link: #2e36db;
  font-size: var(--snc-text-default)
}

.snc .prose h1,
.snc .prose h2,
.snc .prose h3,
.snc .prose h4,
.snc .prose h5 {
  font-size: var(--snc-text-default);
  font-weight: 500
}

.snc .prose h1 a,
.snc .prose h2 a,
.snc .prose h3 a,
.snc .prose h4 a,
.snc .prose h5 a {
  color: var(--link);
  text-decoration: none
}

.snc .prose h1 a:hover,
.snc .prose h2 a:hover,
.snc .prose h3 a:hover,
.snc .prose h4 a:hover,
.snc .prose h5 a:hover {
  opacity: .75
}

.snc .prose h3:first-child a {
  font-size: 1.25rem
}

.snc .prose p {
  margin-bottom: 1rem
}

@media screen and (min-width:901px) {
  .snc {
    --snc-2: 26px;
    --snc-4: 16px;
    --snc-header-height: 90px;
    --snc-message-w: 65%;
    --snc-message-max: 100%;
    --snc-message-min: 0;
    --snc-link-w: 35%;
    --snc-link-max: 500px;
    --snc-text-base: 14px;
    --snc-gutter-display: none
  }

  .snc-accent-border {
    border: 1px solid #e0e0e0
  }
}

@media screen and (min-width:1131px) {
  .snc {
    --snc-2: 32px;
    --snc-4: 110px;
    --snc-header-height: 110px;
    --snc-message-w: 65%;
    --snc-message-max: 850px;
    --snc-message-min: 700px;
    --snc-link-w: 35%;
    --snc-link-max: 420px;
    --snc-link-min: 300px;
    --snc-text-base: 15px;
    --snc-gutter-display: flex
  }
}

@media screen and (min-width:1356px) {
  .snc {
    --snc-2: 34px;
    --snc-4: 120px;
    --snc-header-height: 120px;
    --snc-message-w: 60%;
    --snc-message-max: 880px;
    --snc-message-min: 740px;
    --snc-link-w: 40%;
    --snc-link-max: 500px;
    --snc-link-min: 340px;
    --snc-text-base: 17px
  }
}

@media screen and (min-width:1806px) {
  .snc {
    --snc-2: 38px;
    --snc-4: 130px;
    --snc-message-max: 1000px;
    --snc-link-max: 580px;
    --snc-link-min: 390px;
    --snc-text-base: 18px
  }
}

code,
pre {
  font-family: SÃ¶hne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important
}

code[class=language-plaintext] {
  white-space: pre-line
}

code.hljs,
code[class*=language-],
pre[class*=language-] {
  word-wrap: normal;
  background: none;
  color: #fff;
  -webkit-hyphens: none;
  hyphens: none;
  line-height: 1.5;
  tab-size: 4;
  text-align: left;
  white-space: pre;
  word-break: normal;
  word-spacing: normal
}

pre[class*=language-] {
  border-radius: .3em;
  overflow: auto
}

:not(pre)>code.hljs,
:not(pre)>code[class*=language-] {
  border-radius: .3em;
  padding: .1em;
  white-space: normal
}

.hljs-comment {
  color: hsla(0, 0%, 100%, .5)
}

.hljs-meta {
  color: hsla(0, 0%, 100%, .6)
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e9950c
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword,
.hljs-literal {
  color: #2e95d3
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #00a67d
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #df3079
}

.hljs-bullet,
.hljs-link,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #f22c3d
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #a9aec1
}

.token.punctuation {
  color: #fefefe
}

.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
  color: #ffa07a
}

.token.boolean,
.token.number {
  color: #00e0e0
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
  color: #abe338
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
  color: #00e0e0
}

.token.atrule,
.token.attr-value,
.token.function {
  color: gold
}

.token.keyword {
  color: #00e0e0
}

.token.important,
.token.regex {
  color: gold
}

.token.bold,
.token.important {
  font-weight: 700
}

.token.italic {
  font-style: italic
}

.token.entity {
  cursor: help
}

@media screen and (-ms-high-contrast:active) {

  code[class*=language-],
  pre[class*=language-] {
    background: window;
    color: windowText
  }

  :not(pre)>code[class*=language-],
  pre[class*=language-] {
    background: window
  }

  .token.important {
    background: highlight;
    color: window;
    font-weight: 400
  }

  .token.atrule,
  .token.attr-value,
  .token.function,
  .token.keyword,
  .token.operator,
  .token.selector {
    font-weight: 700
  }

  .token.attr-value,
  .token.comment,
  .token.doctype,
  .token.function,
  .token.keyword,
  .token.operator,
  .token.property,
  .token.string {
    color: highlight
  }

  .token.attr-value,
  .token.url {
    font-weight: 400
  }
}

.PopoverContent {
  z-index: 1000
}

.react-select-container input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.react-select-container .react-select__control {
  --tw-border-opacity: 1;
  align-items: flex-start;
  border-color: rgba(236, 236, 236, var(--tw-border-opacity));
  border-radius: .5rem;
  font-size: .875rem;
  height: 192px;
  line-height: 1.25rem;
  min-height: 192px;
  overflow: auto;
  padding: .5rem
}

.react-select-container .react-select__control:hover {
  --tw-border-opacity: 1;
  border-color: rgba(227, 227, 227, var(--tw-border-opacity))
}

.react-select-container .react-select__control:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 23, 23, var(--tw-bg-opacity));
  border-color: hsla(0, 0%, 100%, .1)
}

.react-select-container .react-select__control:is(.dark *):hover {
  border-color: hsla(0, 0%, 100%, .2)
}

.react-select-container.react-select--invalid .react-select__control {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

.react-select-container.react-select--invalid .react-select__control:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity))
}

.react-select-container .react-select__placeholder {
  padding-left: .5rem;
  padding-right: .5rem
}

.react-select-container .react-select__input-container {
  color: var(--text-secondary);
  padding-left: .5rem;
  padding-right: .5rem
}

.react-select-container .react-select__input {
  height: 2rem
}

.react-select-container .react-select__control--is-focused {
  --tw-border-opacity: 1 !important;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: rgba(227, 227, 227, var(--tw-border-opacity)) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.react-select-container .react-select__control--is-focused:is(.dark *) {
  border-color: hsla(0, 0%, 100%, .2) !important
}

.react-select-container .react-select__value-container {
  padding: 0
}

.react-select-container .react-select__indicators:empty {
  display: none
}

.react-select-container .react-select__multi-value {
  background-color: transparent;
  margin: 0
}

.react-select-container .react-select__multi-value__label {
  padding: 0
}

#intercom-container {
  display: none
}

.dvd-player_wrapperX__nhvkp {
  -webkit-animation: dvd-player_moveX__zrJVd var(--durationX) linear var(--delayX) infinite alternate;
  animation: dvd-player_moveX__zrJVd var(--durationX) linear var(--delayX) infinite alternate
}

.dvd-player_wrapperY__LKifR {
  -webkit-animation: dvd-player_moveY__LVKW_ var(--durationY) linear var(--delayY) infinite alternate;
  animation: dvd-player_moveY__LVKW_ var(--durationY) linear var(--delayY) infinite alternate
}

@-webkit-keyframes dvd-player_moveX__zrJVd {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(var(--boundX));
    transform: translateX(var(--boundX))
  }
}

@keyframes dvd-player_moveX__zrJVd {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(var(--boundX));
    transform: translateX(var(--boundX))
  }
}

@-webkit-keyframes dvd-player_moveY__LVKW_ {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  to {
    -webkit-transform: translateY(var(--boundY));
    transform: translateY(var(--boundY))
  }
}

@keyframes dvd-player_moveY__LVKW_ {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  to {
    -webkit-transform: translateY(var(--boundY));
    transform: translateY(var(--boundY))
  }
}